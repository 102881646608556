import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      backgroundColor: theme.palette.secondary.light,
    },
    container: {
      marginTop: theme.spacing(15),
      marginBottom: theme.spacing(30),
      display: "flex",
      position: "relative",
    },
    item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(0, 5),
    },
    image: {
      height: 55,
    },
    title: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    curvyLines: {
      pointerEvents: "none",
      position: "absolute",
      top: -180,
    },
  });

function ProductValues(props: WithStyles<typeof styles>) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src="/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/map-location-svgrepo-com.svg"
                alt="suitcase"
              />
              <Typography variant="h6" className={classes.title}>
                Applications
              </Typography>
              <Typography variant="h5">
                {
                  "Our application map is our proof of concept of a geographical planning register. It's experimental but people have found it useful so we would like to develop it further."
                }
              </Typography>
              <Typography variant="h5">
              <a href="https://app.planningparrot.uk">{ 'Visit' }</a>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/productHowItWorks3.svg"
                alt="graph"
              />
              <Typography variant="h6" className={classes.title}>
                Alerts
              </Typography>
              <Typography variant="h5">
                {
                  "PlanningParrot Alerts monitors planning applications you are interested in for new comments, dates, documents and decisions and sends change reports to e-mail addresses you specify."
                }
              </Typography>
              <Typography variant="h5">
              <a href="https://alerts.planningparrot.uk">{ 'Visit' }</a>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="productHowItWorks1.svg"
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                Appeals
              </Typography>
              <Typography variant="h5">
                {
                  "Coming Q3 2023. PlanningParrot Appeals will to give you the search flexibiliy that you wish you had through the PINS website."
                }
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default withStyles(styles)(ProductValues);
