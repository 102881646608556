import React from "react";
import {
  withStyles,
  Theme,
  WithStyles,
  createStyles,
} from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(4),
    },
    images: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexWrap: "wrap",
    },
    imageWrapper: {
      position: "relative",
      display: "block",
      padding: 0,
      borderRadius: 0,
      height: "40vh",
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
        height: 100,
      },
      "&:hover": {
        zIndex: 1,
      },
      "&:hover $imageBackdrop": {
        opacity: 0.15,
      },
      "&:hover $imageMarked": {
        opacity: 0,
      },
      "&:hover $imageTitle": {
        border: "4px solid currentColor",
      },
    },
    imageButton: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.common.white,
    },
    imageSrc: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      backgroundPosition: "center 40%",
    },
    imageBackdrop: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: theme.palette.common.black,
      opacity: 0.5,
      transition: theme.transitions.create("opacity"),
    },
    imageTitle: {
      position: "relative",
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
    },
    imageMarked: {
      height: 3,
      width: 18,
      background: theme.palette.common.white,
      position: "absolute",
      bottom: -2,
      left: "calc(50% - 9px)",
      transition: theme.transitions.create("opacity"),
    },
  });

function TeamGrid(props: WithStyles<typeof styles>) {
  const { classes } = props;

  const team = [
    {
      key: "peter",
      url: {
        "photo": "/team/peter.jpeg",
        "linkedin": "https://www.linkedin.com/in/peter-devoy/"
      },
      name: "Peter Devoy",
      accreditations: ["BSc (Hons) Geography with Computing", "Dip. Lvl. 3 IT Practitioners: Software Development"],
      description: "<p>Putting to use his combined education in geography and computing, Peter has speant five years authoring software for customers in the property sector.</p><p>Trusted by some of the most well-known names in conveyancing; if you have handled an environmental search report in the last five years, you have probably handled something Peter's code has produced.</p><p>When Peter saw how challenging it was to get reliable planning data, he began writing software to collect it.  Thus PlanningParrot was born.</p>"
    },
    {
      key: "ana",
      url: {
        "photo": "/team/ana.jpeg",
        "linkedin": "https://www.linkedin.com/in/peter-devoy/"
      },
      name: "Anamaria Sugar",
      accreditations: ["BSc (Hons) Computer Science and Mathematics"],
      description: "<p>Anamaria is a data demon with linguistic leanings.  Her journey with us started as a work placement while she studied.  She left such a good impression that she was invited back for a paid internship.</p><p>Since graduating, Anamaria has gained experience crunching big data and developing AIs for cybersecurity and language parsing applications.</p><p>Now, we are lucky to have her as a permanent member of the PlanningParrot team where she is using her mathematical mastery to make magic in PlanningParrot's AI components.</p>"
    },
    {
      key: "vasily",
      url: {
        "photo": "/team/vasily.jpeg",
        "linkedin": "https://www.linkedin.com/in/vasily-yuferov-operations-expert/"
      },
      name: "Vasily Yuferov",
      accreditations: ["MSc Maritime Navigation", "MSc Business and Economics"],
      description: "<p>In his early career, Vasily was a maritime navigator responsible for charting safe international courses for million dollar vessels.</p><p>Having since accumulated over a decade of experience in business management, he now consulting for million dollar digital businesses; helping their international teams navigate the horizons of our new remote-work world.</p><p>Vasily supports PlanningParrot&apos;s operations and strategy to ensure that our goals are met and that our product meets the expectations of our customers.</p>"
    },
    {
      key: "will",
      url: {
        "photo": "/team/will.jpeg",
        "linkedin": "https://www.linkedin.com/in/william-roper/"
      },
      name: "William Roper (Associate)",
      accreditations: ["MSc Geographical Information Science", "BSc (Hons) Social Anthropology with Development"],
      description: "<p>When it comes to writing code that gobbles government data, few developers can claim the experience of Wiliam Roper.</p><p>                    William has been instrumental on projects such as wheredoivote.co.uk and Democracy Club&apos;s election candidate API &ndash; both of which involved organising unstructured data from local authorities and making it useful to millions of people.</p><p>William supports the PlanningParrot team in delivering our Appeals and Alerts solutions.  His proven experience helps keep our data infrastructure robust and reliable.</p>"
    },
  ];

  return (
    <Container className={classes.root} component="section">
      <Typography variant="h4" marked="center" align="center" component="h2">
        Meet the Team
      </Typography>
      <div>
        <div id="pp-team">
          {
            team.map(
              (teammate) => (
                <figure>
                  <img src={teammate.url.photo} alt={("Photograph of " + teammate.name)} width="300" height="300"/>
                  <figcaption>
                          <strong>{ teammate.name }</strong>
                          &nbsp;
                          <a href={teammate.url.linkedin}>
                              <span className="fab fa-linkedin"></span>
                          </a>
                          <ul className="accreditations">
                              { teammate.accreditations.map((credTitle) => (
                                <li>{credTitle}</li>
                              ))
                              }
                          </ul>
                          <div dangerouslySetInnerHTML= {{ __html: teammate.description }}>
                          </div>                   
                  </figcaption>
                  </figure>
              )
            )
          }
        </div>
      </div>
    </Container>
  );
}

export default withStyles(styles)(TeamGrid);
