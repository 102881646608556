import React from "react";
import {
  withStyles,
  Theme,
  WithStyles,
  createStyles,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import TextField from "../components/TextField";
import RFTextField from "../form/RFTextField";
import Snackbar from "../components/Snackbar";
import Button from "../components/Button";
import { Field, Form, FormSpy } from "react-final-form";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(10),
      marginBottom: 0,
      display: "flex",
    },
    cardWrapper: {
      zIndex: 1,
    },
    card: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: theme.palette.warning.main,
      padding: theme.spacing(8, 3),
    },
    cardContent: {
      maxWidth: 400,
    },
    textField: {
      width: "100%",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    button: {
      width: "100%",
    },
    imagesWrapper: {
      position: "relative",
    },
    imageDots: {
      position: "absolute",
      top: -67,
      left: -67,
      right: 0,
      bottom: 0,
      width: "100%",
      background: "url(/static/onepirate/productCTAImageDots.png)",
    },
    image: {
      position: "absolute",
      top: -28,
      left: -28,
      right: 0,
      bottom: 0,
      width: "100%",
      maxWidth: 600,
    },
  });

function ProductCTA(props: WithStyles<typeof styles>) {
  const { classes } = props;
  const [open, setOpen] = React.useState(false);

  /*const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {*/
  const handleSubmit = (e: any) => {
    debugger;
    console.log(e.email); 
    console.log(arguments);

    const data = {email: e.email};

    //event.preventDefault();
    setOpen(true);
    fetch('http://ppapi:8888/v2/mailing_list', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container className={classes.root} component="section">
      <Grid container>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
          <div className={classes.card}>
          <Form
                  onSubmit={handleSubmit}
                  subscription={{ submitting: true }}
                  //validate={validate}
              >
              {({ handleSubmit: handleSubmit2, submitting }) =>  (
            <form onSubmit={handleSubmit2} className={classes.cardContent}>
              <Typography variant="h2" component="h2" gutterBottom>
                Be in-the-loop
              </Typography>
              <Typography variant="h5">
                {"If you work with planning, we strongly suggest you sign-up to our mailing list for occassional updates."}
              </Typography>
              <Field
                autoComplete="email"
                component={RFTextField}
                disabled={submitting /*|| sent*/}
                fullWidth
                label="Your e-mail"
                margin="normal"
                name="email"
                required
                size="large"
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
                >
                Keep me updated
              </Button>
              <small>You may unsubscribe at any time. * = required.</small>
            </form>
              )}
          </Form>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imagesWrapper}>
          <Hidden smDown>
            <div className={classes.imageDots} />
            <img
              src="https://images.unsplash.com/photo-1608590766866-ae8d39690bbf?auto=format&fit=crop&w=750&h=750&q=80"
              alt="call to action"
              className={classes.image}
            />
          </Hidden>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        closeFunc={handleClose}
        message="We will keep you updated."
      />
    </Container>
  );
}

export default withStyles(styles)(ProductCTA);
